import ReloadingModalDialog from "../action/ReloadingModalDialog";
import ChangeNyukinKubun from "../action/ChangeNyukinKubun";
import ChangeSeisanduki from "../action/ChangeSeisanduki";

class SemiOrderUketorigakuKanriModal {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=SemiOrderUketorigakuKanriModal]").forEach((element) => {
            new ReloadingModalDialog(element, parentElement);
            new SemiOrderUketorigakuKanriModal(element, parentElement);
        });
    }

    private semiOrderUketorigakuKanriModalElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(semiOrderUketorigakuKanriModalElement: HTMLElement, parentElement: HTMLElement) {
        this.semiOrderUketorigakuKanriModalElement = semiOrderUketorigakuKanriModalElement;
        this.parentElement = parentElement;

        this.semiOrderUketorigakuKanriModalElement.addEventListener('ReloadingModalDialog:reloaded', event => this.handleReloaded(event));
    }

    private handleReloaded(event: Event) {
        ChangeNyukinKubun.initComponent(this.semiOrderUketorigakuKanriModalElement);
        ChangeSeisanduki.initComponent(this.semiOrderUketorigakuKanriModalElement);
    }
}

export default SemiOrderUketorigakuKanriModal;
